<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="12">
            <b-row>

              <b-col lg="4">
                <b-form-group>
                  <label>Kode<span class="text-danger mr5">*</span></label>
                  <b-form-input type="text" class="form-control" v-model="row.aru_kode" placeholder="e.g 0001" :formatter="$parent.code" />
                  <VValidate 
                    name="Kode" 
                    v-model="row.aru_kode" 
                    :rules="toValidate(mrValidation.aru_kode)"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="4">
                <b-form-group>
                  <label>Unit Tujuan<span class="text-danger mr5">*</span></label>
                    <b-form-input type="text" class="form-control" v-model="row.aru_unit_tujuan" placeholder="e.g Poli Anak" :formatter="$parent.normalText" />
                    <VValidate 
                        name="Unit Tujuan" 
                        v-model="row.aru_unit_tujuan" 
                        :rules="toValidate(mrValidation.aru_unit_tujuan)"
                    />
                </b-form-group>
              </b-col>

              

              <b-col lg="4">
                <b-form-group>
                  <label>Warna Unit<span class="text-danger mr5">*</span></label>
                  <b-form-input type="color" class="form-control" v-model="row.aru_warna_unit" placeholder="e.g 0001"/>
                  <VValidate 
                    name="Warna Unit" 
                    v-model="row.aru_warna_unit" 
                    :rules="toValidate(mrValidation.aru_warna_unit)"
                  />
                </b-form-group>
              </b-col>

              
              <div class="col-md-9">
                  <div class="form-group">
                      <label for="audioUnitTujuan">Atur Audio<small class="txt_mandatory">*</small></label>
                      <div class="card border">
                      
                      <template v-if="(row.aru_audio||[]).length">
                          <draggable
                            tag="div"
                            class="card-body"
                            v-model="row.aru_audio"
                            @start="startDrag"
                          >
                              <!-- If Empty -->
                              <!-- <span>Belum ada audio ditambahkan</span> -->
                              <div v-for="(v,k) in (row.aru_audio||[])" :key="k" class="btn-group btn-group-sm mr-1 mb-1" >
                                <a href="javascript:;" @click="playAudio(v)" class="btn btn-outline-info btn-sm">
                                    <i class="mr-1 icon-volume-medium"></i>
                                    {{getConfigDynamic(mAudio,v)}}
                                    
                                    <audio controls class="d-none" :id="v+'-aud'">
                                        <source :src="$parent.uploader(getConfigDynamicFile(mAudio,v))" type="audio/mpeg">
                                        Your browser does not support the audio element.
                                    </audio>

                                </a>
                                <a href="javascript:;" @click="(row.aru_audio||[]).splice(k,1)" class="btn alpha-info border-info text-info btn-sm btn-icon" data-popup="tooltip" title="Hapus Audio">
                                    <i class="icon-x"></i>
                                </a>
                              </div>
                          </draggable>
                      </template>
                      <template v-else>
                          <span class="p-2"> Tidak ada data audio yang diinput </span>
                      </template>
                      
                      <div class="card-footer text-right" @click="openModalAudio = true">
                          <a href="javascript:;" class="btn btn-info btn-sm btn-labeled btn-labeled-left" data-toggle="modal" >
                          <b><i class="icon-plus2"></i></b>
                          Tambah Audio
                          </a>
                      </div>
                      </div>
                  </div>
              </div>
              
              <b-col lg="3">
                <b-form-group>
                  <label>Kategori Display<span class="text-danger mr5">*</span></label>
                  <v-select @input="ubahKategoriTambahan" placeholder="Pilih Kategori Display" v-model="row.aru_category" :options="Config.mr.mUnitCategory" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  
                  <VValidate 
                    name="Unit" 
                    v-model="row.aru_category" 
                    :rules="{required : 1}"
                  />
                </b-form-group>
                <b-form-group v-if="row.aru_category == 'ranap' || row.aru_category == 'informasi'">
                  <label>Kategori Display Tambahan</label>
                  <v-select name="kategori_display_tambahan" :reduce="v=>v.value" label="text" :clearable="true" placeholder="Pilih Kategori Display" v-model="row.aru_category_2" :options="mUnitCategoryTambahan"></v-select>
                </b-form-group>
              </b-col>

              <b-col lg="4" v-if="row.aru_category=='loket'">
                <label>Pilih Dokter Tujuan<span class="text-danger mr5">*</span></label>
                <v-select 
                  multiple 
                  :options="mDokter" 
                  v-model="row.aru_dokter"
                  label="text"
                  class="vs__multiple"
                  placeholder="Pilih Dokter" 
                  :reduce="(v) => v.value"
                >
                </v-select>
                <VValidate 
                    name="Dokter Tujuan" 
                    v-model="row.aru_dokter" 
                    :rules="{required : 1}"
                  />
              </b-col>



              <b-col lg="4">
                <b-form-group class="mt-3">
                  <label>Identifikasi Ruangan Satu Sehat <span class="text-danger mr5">*</span></label>
                  <b-form-input :disabled="row.disabled_identifier" type="text" class="form-control" v-model="row.aru_satu_sehat_identifier" placeholder="cth. K-1-P" :formatter="$parent.identifier" />
                </b-form-group>
                <small class="text-info">Pastikan untuk Identifikasi ruangan sudah benar, dikarenakan tidak bisa diedit kembali</small>
              </b-col>            

              <b-col lg="6">
                <b-form-group class="mt-3">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>

    <b-modal v-model="openModalAudio" scrollable :title="'Pilih Audio'" size="md" ok-only ok-title="Simpan">
        <div class="modal-body p-0">
        <div class="row mb-2">
          <div class="col-md-12">
              <b-input-group>
                <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                  <input type="text" class="form-control" v-model="searhPoliVal" :placeholder="'Search'">
                  <div class="form-control-feedback">
                    <i class="icon-search4"></i>
                  </div>
                </div>
              </b-input-group>
          </div>
        </div>
        <table class="table table-sm table-bordered table-hover">
            <thead>
            <tr>
                <th width="20">#</th>
                <th>Nama</th>
                <th width="150">Preview</th>
            </tr>
            </thead>
            <tbody>
            <b-form-checkbox-group
                class="display-c"
                id="checkbox-group-2 display-c"
                v-model="row.aru_audio"
                name="flavour-2"
            >

                <tr v-for="(v,k) in (mAudio||[])" :key="k" :class="(v.text.toLowerCase()).indexOf(searhPoliVal) !== -1 ? '' : 'd-none'">

                    <td>
                        <b-form-checkbox :value="v.value"></b-form-checkbox>
                    </td>
                    <td>{{v.text}}</td>
                    <td>
                      <template v-if="v.isOpen">
                        <audio controls>
                            <source :src="$parent.uploader(v.file)" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                      </template>
                      <template v-else>
                        <a href="javascript:;" @click="mAudio[k].isOpen = true" class="list-icons-item"
                          data-toggle="tooltip" data-placement="top" title="Lihat File">Lihat File</a>
                      </template>
                    </td>
                </tr>
            </b-form-checkbox-group>
            </tbody>
        </table>
        
        </div>
        <div class="modal-footer p-0 d-block bg-white">
        <div class="form-group mb-0 mt-2">
            <label for="">Audio dipilih:</label>
            
            <draggable
              tag="div"
              v-model="row.aru_audio"
              @start="startDrag"
            >
                <div class="btn-group btn-group-sm mr-1 mb-1" v-for="(v,k) in (row.aru_audio||[])" :key="k">
                    <a href="javascript:;" @click="playAudio2(v)" class="btn btn-outline-info btn-sm">
                      <i class="mr-1 icon-volume-medium"></i>
                        {{getConfigDynamic(mAudio,v)}}
                    </a>                    
                    <audio controls class="d-none" :id="v+'-aud2'">
                        <source :src="$parent.uploader(getConfigDynamicFile(mAudio,v))" type="audio/mpeg">
                        Your browser does not support the audio element.
                    </audio>
                </div>
            </draggable>

            <span v-if="!(row.aru_audio||[]).length"> - </span>

        </div>
        </div>
    </b-modal>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._
import draggable from 'vuedraggable'

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
    mPoli: Array,
    mAudio: Array,
    mDokter: Array,
  },
  components: {
    draggable,
  },
  data(){
    return{
      searhPoliVal: '',
      openModalAudio : false
    }
  },
  computed: {
    mUnitCategoryTambahan(){
      return this.Config.mr.mUnitCategory.filter(v => v.value == (this.row.aru_category == 'informasi'  ? 'ranap' : 'informasi'))
    }
  },
  methods: {
    ubahKategoriTambahan(){
      this.row.aru_category_2 = null
    },
    toValidate(val){
      return {...val}
    },
    startDrag(){
      console.log("test")
      // $(".sortable-fallback").removeAttr("style")
      // $(".sortable-fallback").css("display","none")
    },
    
    
    playAudio(id){
      let audio = new Audio(this.$parent.uploader(this.getConfigDynamicFile(this.mAudio,id)))
      audio.play()  
    },

    playAudio2(id){
      let audio2 = new Audio(this.$parent.uploader(this.getConfigDynamicFile(this.mAudio,id)))
      audio2.play()
    },
    getConfigDynamic(master, value) {
        let text = ''
        if (value) {
            let index = (master||[]).findIndex(x => x.value == value)
            if (index !== -1) {
            text = master[index]['text']
            }
        }
        return text
    },
    
    getConfigDynamicFile(master, value) {
        let text = ''
        if (value) {
            let index = (master||[]).findIndex(x => x.value == value)
            if (index !== -1) {
            text = master[index]['file']
            }
        }
        return text
    },
  },
}
</script>